@import "settings";
@import "../base/app";

.top-bar .menu-text {
  padding: 0 1rem;

  display: flex;
  align-items: center;

  #header-image {
    display:inline;
    content: url('../../img/fed4fire-userauth.svg');
    background-color:white;
    width: 35px;
    margin-right: 8px;
  }
}
