/** auth/pending_users.html */

.pending-users-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(580px, 600px));
  grid-gap: 24px;

  .pending-user-wrapper {

    .pending-user-info {
      .pending-user-details {
        display: grid;
        grid-gap: 4px;
        grid-template-columns: repeat(2, 1fr);

        @include breakpoint(medium down) {
          grid-template-columns: 1fr;
        }
      }

      .pendinguser-fullname,
      .pendinguser-email,
      .pendinguser-company,
      .pendinguser-location,
      .pendinguser-requesttime,
      .pendinguser-portalhome{
        color: $dark-gray;
        margin-bottom: 0;
        font-size: 0.85em;
      }

      .pendinguser-fullname {
        @include icon($user);
      }

      .pendinguser-email {
        @include icon($email);
      }

      .pendinguser-email a {
        color: $dark-gray;
      }

      .pendinguser-company {
        @include icon($id-badge);
      }

      .pendinguser-location {
        @include icon($location-pin);
      }

      .pendinguser-requesttime {
        @include icon($time);
      }
      .pendinguser-portalhome {
        @include icon($home);
      }
    }

    .pending-user-actions {

      .approve-user:before {
        @extend %fa-icon;
        @extend .fal;
        margin-right: 10px;
        content: fa-content($fa-var-user-check);

      }

      .deny-user:before {
        @extend %fa-icon;
        @extend .fal;
        margin-right: 10px;
        content: fa-content($fa-var-user-times);
      }
    }
  }
}


;@import "sass-embedded-legacy-load-done:569";