/** oauth/manage.html */


.oauth-cards {

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 24px;
  margin: 12px 0 24px;

  .oauth-card {
    background-color: $white;
    padding: 20px;
    border: 1px solid $medium-gray;

    .title {
      padding-right: 8px;
      color: $dark-gray;
    }

    .actions {
      padding-top: 24px;

      .remove-auth:before {
        @extend %fa-icon;
        @extend .fal;
        margin-right: 10px;
        content: fa-content($fa-var-times);
      }
    }

    .auth-scope-wrapper {
      span.title {
        vertical-align: top;
      }

      ul {
        display: inline-block;
      }
    }
  }

  .auth-created-icon {
    @extend %fa-icon;
    @extend .fal;

    padding-right: 4px;

    &:before {
      content: fa-content($fa-var-sparkles);
    }
  }

  .auth-scope-icon {
    @extend %fa-icon;
    @extend .fal;

    padding-right: 4px;

    &:before {
      content: fa-content($fa-var-eye);
    }
  }
}

.no-oauths {
  margin: 24px 0;
  background-color: $white;
  padding: 24px;
}

;@import "sass-embedded-legacy-load-done:568";