/** auth/project_invite.html */

.invite-options {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 24px;

  .invite-option {
    background-color: $light-gray;
    padding: 24px;
    text-align: center;

    h3 {
      margin-bottom: 24px;
    }
  }
}
;@import "sass-embedded-legacy-load-done:567";