//custom colors
$slices-orange: #feb000;
$slices-red: #f04e37;
$slices-green: #66cc66;
$slices-blue: #0099cc;

.services-cards {
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}

.service-card {
  padding: 24px;
  background-color: white;
  border: 1px solid $medium-gray;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  text-align: center;

  .letters-highlight {
    background-color: $slices-blue;
    border-radius: 50%;
    height: 64px;
    width: 64px;
    color: white;
    font-size: 1.5rem;
    text-transform: uppercase;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 12px;
  }

  &:nth-of-type(4n + 1) .letters-highlight {
    background-color: $slices-orange;
  }

  &:nth-of-type(4n + 2) .letters-highlight {
    background-color: $slices-red;
  }

  &:nth-of-type(4n + 3) .letters-highlight {
    background-color: $slices-green;
  }

  &:nth-of-type(4n + 0) .letters-highlight {
    background-color: $slices-blue;
  }
}

.services-cards.basic-services {
  .service-card {
    &:nth-of-type(4n + 3) .letters-highlight {
      background-color: $slices-orange;
    }

    &:nth-of-type(4n + 0) .letters-highlight {
      background-color: $slices-red;
    }

    &:nth-of-type(4n + 1) .letters-highlight {
      background-color: $slices-green;
    }

    &:nth-of-type(4n + 2) .letters-highlight {
      background-color: $slices-blue;
    }
  }
}

div.services-cards + h4 {
  margin-top: 1.25rem;
}

;@import "sass-embedded-legacy-load-done:632";