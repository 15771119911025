.hero-section {
  background-color: $theme-color-secondary;
  background-image: url("data:image/svg+xml,%3Csvg width='84' height='48' viewBox='0 0 84 48' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h12v6H0V0zm28 8h12v6H28V8zm14-8h12v6H42V0zm14 0h12v6H56V0zm0 8h12v6H56V8zM42 8h12v6H42V8zm0 16h12v6H42v-6zm14-8h12v6H56v-6zm14 0h12v6H70v-6zm0-16h12v6H70V0zM28 32h12v6H28v-6zM14 16h12v6H14v-6zM0 24h12v6H0v-6zm0 8h12v6H0v-6zm14 0h12v6H14v-6zm14 8h12v6H28v-6zm-14 0h12v6H14v-6zm28 0h12v6H42v-6zm14-8h12v6H56v-6zm0-8h12v6H56v-6zm14 8h12v6H70v-6zm0 8h12v6H70v-6zM14 24h12v6H14v-6zm14-8h12v6H28v-6zM14 8h12v6H14V8zM0 8h12v6H0V8z' fill='%23f5debb' fill-opacity='0.1' fill-rule='evenodd'/%3E%3C/svg%3E");

  height: 500px;

  padding: 24px 0;

  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: space-between;

  @include breakpoint(medium down) {
    height: initial;
  }

  .hero-body {
    max-width: 1170px;
    margin: 0 auto;
    flex: 1 0 auto;

    display: grid;
    grid-template-columns: 1fr 370px;
    grid-template-rows: auto;
    //grid-gap: 32px;
    align-items: center;

    @include breakpoint(medium down) {
      grid-template-columns: 1fr;
    }

    .intro {
      margin: 32px;
      max-width: 670px;
      color: $white;

      h1 {
        font-weight: bold;
      }

      p {
        font-size: 1.25rem;
        line-height: 150%;
        margin: 1rem 0;
      }

      a, a:hover, a:active {
        color: $white;
      }

      .button {
        margin: 0;
      }


      .hero-open-access {
        a {
          color: $gray-4;
        }
      }
    }

    .login {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin: 32px;

      img {

      }
    }

    .login-wrapper {
      display: flex;
      flex-direction: column;
      width: 370px;

      background-color: $white;
      border-radius: 6px;
      box-shadow: 0 2px 3px rgba(10, 10, 10, .1); //      0 0 0 1px rgba(10, 10, 10, .1);

      .login-title {

        background: $gray-4;
        text-align: center;
        padding: 8px;
        border-radius: 6px 6px 0 0;

        display: flex;
        flex-direction: column;

        h5 {
          font-weight: 600;
          margin: 0.4rem 0;
        }
      }

      .buttons-wrapper {
        padding: 8px 32px 32px 32px;
        text-align: center;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

    }

    #contact-us:before, #discover-testbeds:before, #login-button:before, #signup-button:before {
      @extend %fa-icon;
      @extend .fal;

      padding-right: 8px;
    }

    #contact-us:before {
      content: fa-content($fa-var-envelope);
    }

    #discover-testbeds:before {
      content: fa-content($fa-var-search);
    }

    #login-button:before {
      content: fa-content($fa-var-sign-in);
    }

    #signup-button:before {
      content: fa-content($fa-var-id-card-alt);
    }
  }


}

.features-section {
  padding: 64px;
  background-color: $white;
}

.features-wrapper {
  max-width: 1170px;
  margin: 0 auto;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-auto-flow: dense;
  grid-gap: 64px;

  .feature-icon {
    color: $theme-color-primary;

    &:after {
      color: $theme-color-secondary;
      opacity: 0.9;
    }

    text-shadow: 3px 3px rgba(10, 10, 10, 0.2);
    font-size: 3rem;
    margin: 0 0 16px 0;
  }
}

.domains-section {
  padding: 64px;

  .domains-wrapper {
    max-width: 1170px;
    margin: 0 auto;

    .domain-buttons-wrapper {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(150px, 200px));
      grid-gap: 16px;

      .domain {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          margin: 0;
        }
      }
    }

  }

  p {
    max-width: 760px;
  }


}

.directory-icon, .contact-icon {
  vertical-align: top;
  margin-left: 24px;

  color: $theme-color-primary;

  &:after {
    color: $theme-color-secondary;
    opacity: 0.9;
  }

  text-shadow: 3px 3px rgba(10, 10, 10, 0.2);
  font-size: 4rem;
}

.contact-section {
  background-color: $white;
  padding: 64px;

  .contact-wrapper {
    max-width: 1170px;
    margin: 0 auto;

    display: grid;
    grid-template-columns: 760px 1fr;
    grid-gap: 64px;

    @include breakpoint(medium down) {
      grid-template-columns: 1fr;
      grid-gap: 16px;
    }

  }



  .button:before {
    @extend %fa-icon;
    @extend .fal;

    content: fa-content($fa-var-envelope);
    padding-right: 8px;
  }

}


.contact-reasons {
  max-width: 350px;
  padding: 8px;
  border: 2px solid $theme-color-primary;

  border-radius: 6px;
  box-shadow: 0 2px 3px rgba(10, 10, 10, .1);

  ul {
    list-style: none;
    padding: 0;
  }

  li:before {
    @extend %fa-icon;
    @extend .fal;

    padding-right: 8px;
    content: fa-content($fa-var-check);
    color: $theme-color-primary;

  }

}

.explore-home-body footer {
  background-color: $white;
}

.f4f-logo-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.f4f-logo {
  max-width: 250px;

}

.domain-buttons-wrapper {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

  .domain {
    text-align: center;
    padding: 8px;
    border: 4px solid $dark-gray;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;

    img {
      max-width: 100px;
      margin-right: 24px;
      filter: drop-shadow(2px 4px 6px gray);
    }

    h3 {
      display: inline;
      justify-self: center;
      align-self: center;

    }
  }

  .domain-wired {
    border-color: #73ad61;
  }

  .domain-wireless {
    border-color: #659ebc;
  }

  .domain-iot {
    border-color: #39683e;
  }

  .domain-5g {
    border-color: #cc5b31;
  }

  .domain-openflow {
    border-color: #de9932;
  }

  .domain-cloud {
    border-color: #65588d;
  }

  .domain-bigdata {
    border-color: #c94960;
  }

  .domain-sdn {
    border-color: #7D1DDE;
  }

  .domain-ai {
    border-color: #3EDECD;
  }

  .domain-cognitiveradio {

    border-color: #F9CE41;
  }

  .domain-hpc {
    border-color: #802F12;
  }

  .domain-gpu {
    border-color: #76b900;
  }
}


.testbed-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(600px, 1fr));
 // grid-auto-rows: 1fr;
  grid-gap: 24px;
}

.testbed-card {
  padding: 24px;
  border: 1px solid $light-gray;
  //margin-bottom: 24px;

  em {
    color: $dark-gray;
  }

  a.button + a.button {
    margin-left: 8px;
  }
}


.missing-filter {
  color: $red-5;
  text-decoration: line-through;
}


// resources loading

.resources-per-testbed-pending {
  position: relative;
  min-height: 300px;

  #loading-overlay{
    position: absolute;
    top:0;
    left: 0;
    background-color: rgba($light-gray,0.4);
    height: 100%;
    width: 100%;

    .lds-grid {
      position: absolute;
      left: calc(50% - 40px);
      top: 100px;
    }
  }
}

.lds-grid {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-grid div {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: $dark-gray;
  animation: lds-grid 1.2s linear infinite;
}
.lds-grid div:nth-child(1) {
  top: 8px;
  left: 8px;
  animation-delay: 0s;
}
.lds-grid div:nth-child(2) {
  top: 8px;
  left: 32px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(3) {
  top: 8px;
  left: 56px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(4) {
  top: 32px;
  left: 8px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(5) {
  top: 32px;
  left: 32px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(6) {
  top: 32px;
  left: 56px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(7) {
  top: 56px;
  left: 8px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(8) {
  top: 56px;
  left: 32px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(9) {
  top: 56px;
  left: 56px;
  animation-delay: -1.6s;
}
@keyframes lds-grid {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.icon-alert {
  color: $alert-color;
}
;@import "sass-embedded-legacy-load-done:617";