/** profile/overview.html */

.profile-wrapper {
  font-size: 14px;
  flex: 1 1 auto;
}

.profile-content {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 24px;

  @include breakpoint(medium down) {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
}

.profile-cards {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
}

.profile-info {
  padding: 24px;
  background-color: white;
}

.card {
  //margin-bottom: 24px;
  background-color: $white;

  display: flex;
  flex-direction: column;

  .card-header,
  .card-content,
  .card-actions {
    padding: 12px 24px;
  }

  .card-header {
    min-height: 48px;
    padding: 0 24px;
    border-bottom: 1px solid $medium-gray;
    border-radius: 2px 2px 0 0;

    h3 {
      padding: 16px 0;
      color: $black;
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 0;
    }
  }

  .card-content {
    flex-grow: 1;
  }

  .card-actions {
    form {
      display: inline;
    }
  }
}

.profile-info {
  display: flex;
  flex-direction: column;
  align-items: center;

  .user-avatar-image {
    width: 100px;
    height: 100px;
    margin: 12px;
  }

  .username {
    //  line-height: 28px;
    //  font-weight: 500;
    //  color: $black;
    margin-bottom: 1rem;

    .profileinfo-value {
      font-weight: 500;
    }

    //  font-size: 20px;
  }

  .account-status-icon {
    @extend %fa-icon;
    @extend .fal;

    padding-right: 4px;

    &.admin:before {
      content: fa-content($fa-var-user-crown);
      color: $green-6;
    }

    &.active:before {
      content: fa-content($fa-var-check-circle);
      color: $green-6;
    }

    &.pending:before {
      content: fa-content($fa-var-hourglass);
      color: $orange-6;
    }

    &.denied:before {
      content: fa-content($fa-var-times-circle);
      color: $red-6;
    }

    &.disabled:before {
      content: fa-content($fa-var-snooze);
      color: $gray-7;
    }
  }

  .profile-details {
    margin: 12px auto;
    width: 100%;

    .profileinfo-name {
      color: $dark-gray;
    }

    .username .profileinfo-name {
      @include icon($id-badge, $c: $theme-color-primary);
    }

    .urn .profileinfo-name {
      @include icon($id-badge, $c: $theme-color-primary);
    }

    .urn .profileinfo-value {
      font-family: $font-family-monospace;

      .profileinfo-value-alert {
        color: get-color(alert);
      }
    }

    .email .profileinfo-name {
      @include icon($email, $c: $theme-color-primary);
    }

    .company .profileinfo-name {
      @include icon($briefcase, $c: $theme-color-primary);
    }

    .location .profileinfo-name {
      @include icon($location-pin, $c: $theme-color-primary);
    }

    .fullname .profileinfo-name {
      @include icon($user, $c: $theme-color-primary);
    }
  }

  .divider {
    width: 100%;
    margin: 12px 0;

    border-bottom: 1px dashed $medium-gray;
  }

  .actions {
    margin: 12px 0;

    a {
      display: block;
    }

    .profile-action-edit {
      @extend %fa-icon;
      @extend .fal;

      padding-right: 4px;

      &:before {
        content: fa-content($fa-var-pencil);
      }
    }

    .profile-action-change-password {
      @extend %fa-icon;
      @extend .fal;

      padding-right: 4px;

      &:before {
        content: fa-content($fa-var-key);
      }
    }
  }
}

.request-join-project-icon {
  @extend %fa-icon;
  @extend .fal;

  padding-right: 4px;

  &:before {
    content: fa-content($fa-var-user-plus);
  }
}

.request-new-project-icon {
  @extend %fa-icon;
  @extend .fal;

  padding-right: 4px;

  &:before {
    content: fa-content($fa-var-users);
  }
}

.download-pem-icon {
  @extend %fa-icon;
  @extend .fal;

  padding-right: 4px;

  &:before {
    content: fa-content($fa-var-download);
  }
}

.getting-started-card {
  .card-header {
    h3:after {
      @extend %fa-icon;
      @extend .fal;

      padding-left: 8px;
      content: fa-content($fa-var-rocket);
    }
  }

  .pending-step:before,
  .completed-step:before {
    @extend %fa-icon;
    @extend .fal;

    padding-right: 8px;
  }

  .pending-step:before {
    content: fa-content($fa-var-square);
  }

  .completed-step {
    color: $dark-gray;

    &:before {
      content: fa-content($fa-var-check-square);
    }
  }

  ul {
    list-style-type: none;
    font-size: 1.2em;
    line-height: 2em;
  }
}

.project-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 24px;

  .project-card {
    padding: 20px;
    border: 1px solid $medium-gray;

    position: relative;
    overflow: hidden; // for the status ribbon
    min-height: 120px;

    .edit-button {
      position: absolute;
      top: 24px;
      right: 24px;

      @extend %fa-icon;
      @extend .fal;

      &:before {
        content: fa-content($fa-var-pencil);
      }
    }

    .project-role-title,
    .project-members-title {
      color: $dark-gray;
      padding-right: 4px;
    }

    .project-role-icon {
      @extend %fa-icon;
      @extend .fal;

      padding-right: 4px;

      &.project-role-lead:before {
        content: fa-content($fa-var-users-crown);
      }
    }

    .project-members-icon {
      @extend %fa-icon;
      @extend .fal;

      padding-right: 4px;

      &:before {
        content: fa-content($fa-var-users);
      }
    }

    .status-ribbon {
      width: 200px;
      background: $theme-color-primary;
      position: absolute;

      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;

      text-transform: uppercase;
      text-align: center;
      height: 48px;
      letter-spacing: 1px;
      color: $white;

      top: 18px;
      right: -56px;
      transform: rotate(45deg);

      box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);

      &.denied,
      &.expired {
        background: get-color(alert);
      }

      &.expiring {
        background-color: get-color(warning);
      }
    }
  }
}

.slice-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 24px;
  margin: 12px 0 24px;

  .slice-card {
    padding: 20px;
    border: 1px solid $medium-gray;

    .slice-name .label {
      vertical-align: text-bottom;
      margin-left: 12px;
    }

    .slice-expiration-wrapper > .title,
    .slice-creation-wrapper > .title,
    .slice-slivers-wrapper > .title,
    .slice-rspec-wrapper > .title {
      color: $dark-gray;
      padding-right: 4px;
    }

    .slice-created-icon {
      @extend %fa-icon;
      @extend .fal;

      padding-right: 4px;

      &:before {
        content: fa-content($fa-var-sparkles);
      }
    }

    .slice-expiration-icon {
      @extend %fa-icon;
      @extend .fal;

      padding-right: 4px;

      &:before {
        content: fa-content($fa-var-arrow-alt-to-right);
      }
    }

    .slice-expiration-wrapper {
      margin-bottom: 24px;
    }

    .slice-slivers-icon {
      @extend %fa-icon;
      @extend .fal;

      width: 20px;

      &:before {
        content: fa-content($fa-var-tally);
      }
    }

    .slice-rspec-icon {
      @extend %fa-icon;
      @extend .fal;

      width: 20px;

      &:before {
        content: fa-content($fa-var-file-code);
      }
    }

    .slice-rspec-download-link {
      margin-bottom: 0;

      &:before {
        @extend %fa-icon;
        @extend .fal;

        padding-right: 4px;
        content: fa-content($fa-var-cloud-download);
      }
    }
  }

  .more-slices-card {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

;@import "sass-embedded-legacy-load-done:571";