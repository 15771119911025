/** oauth/list_clients.html */


.oauth-clients-table-wrapper {
  background-color: $white;
  padding: 24px;
}

.header-buttons {
  margin-top: 1rem;

  a {
    margin-bottom: 0;
  }
}

.button.add-oauth-client {


  &:before {
    @extend %fa-icon;
    @extend .fal;

    content: fa-content($fa-var-plus-square);
    padding-right: 8px;
  }
}

/** oauth/create_client.html */

.add-oauth-client-form {
  max-width: 640px;

  ul {
    list-style-type: none;
    margin-left: 0;
  }
}

/** oauth/authorize.html */

.oauth-authorize-wrapper {
  display: flex;
  justify-content: center;
}

.oauth-authorize-form {
  max-width: 640px;
  background: $white;
  padding: 24px;

  h4 {
    text-align: center;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .form-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    max-width: 400px;

    margin: 0 auto;
  }

  p {
    margin: 1rem;
  }

  ul {
    margin: 1rem 2rem;

    strong {
      font-weight: 600;
    }
  }

  .oauth-authorize-button:before {
    @extend %fa-icon;
    @extend .fal;
    margin-right: 10px;
    content: fa-content($fa-var-check);

  }

  .oauth-authorizing-button:before {
    @extend %fa-icon;
    @extend .fal;
    margin-right: 10px;
    content: fa-content($fa-var-spinner);

  }

  .oauth-deny-button:before {
    @extend %fa-icon;
    @extend .fal;
    margin-right: 10px;
    content: fa-content($fa-var-times);

  }
}


td.oauth-client-id, td.oauth-client-secret {
  font-family: monospace;
}
td.oauth-client-redirect-uri {
  white-space: pre-wrap;
}

;@import "sass-embedded-legacy-load-done:611";