.loggedin-container {
  display: flex;
  min-height: 100vh;

  .loggedin-aside {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    max-width: 260px;
    min-width: 200px;
    transition: width 0.2s ease-in;
    //background-color: $theme-color-primary;
    background: linear-gradient(
      145deg,
      $theme-color-primary,
      $theme-color-secondary
    );
    z-index: 10;
    min-height: 100vh;

    box-shadow: 2px 0 6px $medium-gray;

    .header-image-container {
      display: flex;
      justify-content: center;

      img {
        margin: 26px;
      }
    }

    h2 {
      font-size: 16px;
      font-weight: bold;
      line-height: 1;
      padding: 20px 8px;
      color: $white;

      display: block;
      text-align: center;
    }

    .sidebar-nav {
      padding: 12px 0;
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      //border-right: 1px solid $medium-gray;

      .side-nav {
        @include menu-base;
        @include menu-direction($dir: vertical);

        i.icon {
          padding-right: 8px;
        }

        a {
          color: $white;
        }

        a:hover {
          background-color: $loggedin-navbar-link-hover-background-color;
        }

        li.has-tip {
          border-bottom: none;
        }

        li.active,
        .menu li.active {
          color: $white;
          background-color: $loggedin-navbar-link-active-background-color;
          //border-right: 2px solid $loggedin-navbar-link-color;

          a {
            background-color: transparent;
            color: $white; //$loggedin-navbar-link-color;
          }
        }

        .menu {
          margin-left: 0;

          li {
            a {
              padding-left: 2rem;
            }
          }
        }
      }

      .admin {
        background-color: $loggedin-navbar-admin-color; // lighten($theme-color-primary, 40%);
        margin: 8px 0;
        padding: 8px 0;

        h6 {
          font-size: 1.1rem;
          font-weight: 500;
          padding: 0 16px;
          color: $white;
          font-variant: small-caps;
        }

        .side-nav {
          padding: 0;
          margin: 0;
          flex: 1;

          a:hover {
            background-color: $loggedin-navbar-admin-hover-background-color;
          }

          li.active {
            color: $white;
            background-color: $loggedin-navbar-admin-active-background-color;
            //border-right: 2px solid $loggedin-navbar-link-color;

            a {
              color: $white; //$loggedin-navbar-link-color;
            }
          }
        }

        .side-nav + h6 {
          padding-top: 8px;
        }
      }

      .aside-bottom {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: end;

        .user-avatar-image {
          width: 24px;
          padding-right: 8px;
        }
      }
    }

    &.collapsed {
      width: 80px;
      min-width: 80px;
      transition: width 0.2s ease-in;

      h2 {
        padding: 8px;
      }

      .sidebar-nav {
        padding: 8px 0;

        .side-nav {
          a {
            padding-left: 0;
            padding-right: 0;
          }

          i.icon {
            padding: 0 32px;
          }

          span {
            display: none;
          }

          .menu {
            display: none;
          }
        }
      }
    }
  }

  .loggedin-content {
    flex: 1 1 auto;

    display: flex;
    flex-direction: column;

    .header-wrapper {
      padding: 24px;
      background-color: $white;
      border-bottom: 1px solid $medium-gray;

      h1 {
        font-size: 24px;
        font-weight: 500;
      }

      .header-stats {
        display: flex;

        .header-stat {
          margin-right: 36px;

          .stat {
            line-height: initial;
          }

          p + .stat {
            margin-top: 0;
          }
        }
      }

      p {
        margin-bottom: 0;
      }

      .header-actions {
        float: right;
      }
    }

    .main-content {
      padding: 0;
      margin: 0;
      background-color: transparent;

      max-width: initial;
    }

    .content-wrapper {
      margin: 24px;

      .simple-content {
        background-color: $white;
        padding: 24px;
      }

      .card {
        margin-bottom: 24px;
      }
    }
  }

  .top-bar {
    position: relative;
    height: 64px;
    background-color: $white;
    background-image: none;

    box-shadow: 0 1px 4px $medium-gray;

    a:not(.button) {
      color: $black;
    }

    a.menu-fold-trigger {
      padding: 16px;

      img {
        height: 24px;
        width: 24px;
      }

      &.collapsed {
        img {
          transform: scaleX(-1);
        }
      }
    }
  }
}

//icons
.profile-action-admin {
  @extend %fa-icon;
  @extend .fal;

  padding-right: 4px;

  &:before {
    content: fa-content($fa-var-tools);
  }
}

;@import "sass-embedded-legacy-load-done:546";