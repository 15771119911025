/** large-message.html */

.large-message {
  text-align: center;

  .large-message-content {
    padding: 0 12%;
  }

  .content-title {
    justify-content: center;
  }

  .icon-wrapper {
    margin: 24px;
  }

  .message-content-wrapper {
    background-color: $light-gray;
    padding: 24px;
    margin: 24px;

    p:last-of-type {
      margin-bottom: 0;
    }

  }
}

.success-icon {
  display: block;
  text-align: center;
  font-size: 10em;
  color: get-color(success);
}

.error-icon {
  display: block;
  text-align: center;
  font-size: 10em;
  color: get-color(alert);
}

/** logged-in-large-message.html */

.loggedin-content {
  .large-message-content {
    padding: 24px;
    background-color: $white;
    text-align: center;

  }

  .icon-wrapper {
    margin: 24px;
  }

  .message-content-wrapper {
    background-color: $light-gray;
    padding: 24px;
    margin: 24px;

    p:last-of-type {
      margin-bottom: 0;
    }

  }
}

;@import "sass-embedded-legacy-load-done:550";