@import "common_settings";
@import "~foundation-sites/scss/foundation";
//fontawesome icons
$fa-font-path: "../../fa/webfonts/";
@import "../../fa/scss/fontawesome";
@import "../../fa/scss/light";
@import "../../fa/scss/duotone";

//themify icons

@import "../../themify-icons/variables";
@import "../../themify-icons/mixins";
@font-face {
	font-family: 'themify';
	src:url(../../themify-icons/fonts/themify.eot?-fvbane);
	src:url(../../themify-icons/fonts/themify.eot?#iefix-fvbane) format('embedded-opentype'),
		url(../../themify-icons/fonts/themify.woff?-fvbane) format('woff'),
		url(../../themify-icons/fonts/themify.ttf?-fvbane) format('truetype'),
		url(../../themify-icons/fonts/themify.svg?-fvbane#themify) format('svg');
	font-weight: normal;
	font-style: normal;
}
@import "../../themify-icons/core";
@import "../../themify-icons/extras";
@import "../../themify-icons/icons";


@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

@include foundation-xy-grid-classes;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-switch;
@include foundation-table;
// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
//@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip;

// Containers
@include foundation-accordion;
@include foundation-media-object;
@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
// @include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

@import 'portal.scss';


/** adaptations of the foundation theme */

.callout {
  margin: 0 0 1rem 0;
  padding: 1rem;
  border: 1px solid rgba(10, 10, 10, 0.05);
  position: relative;
  color: #0a0a0a;
  background-color: white;
  border-radius: 3px;
}

.generic-form {
	max-width: 600px;
}
;@import "sass-embedded-legacy-load-done:19";